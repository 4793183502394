import { DATETIME_FORMAT, DATE_FORMAT, DEFAULT_EMPTY, DELAY_TRANSITION } from 'constants/common';
import _, { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import i18n from './i18n';
import { LocalStorage, STORAGE_KEY } from './localStorage';
import { v4 as uuidv4 } from 'uuid';
import { AppConfigType } from 'types/property.type';
import { getBranchConfig } from 'services/property.service';

const Storage = new LocalStorage();
const AppConfig = Storage.getStorageItem(STORAGE_KEY.APP_CONFIG) as AppConfigType;

export const showData = (data: any) => {
  if (isNull(data) || isUndefined(data) || data.length === 0) return DEFAULT_EMPTY;
  else return data;
};

export const formatPrice = (price: number) => {
  return (
    price
      .toLocaleString('it-IT', { style: 'currency', currency: 'vnd' })
      .replace(/[^0-9\.-]+/g, '') + 'đ'
  );
};

export const isSuccessCode = (code?: number) => {
  return code ? String(code).length === 4 : false;
};

export const disableAllInput = () => {
  const listInput = document.getElementsByTagName('input');
  for (let i = 0; i < listInput.length; i++) {
    const input = listInput[i] as HTMLInputElement;
    input.disabled = true;
  }
};

export const enableAllInput = () => {
  const listInput = document.getElementsByTagName('input');
  for (let i = 0; i < listInput.length; i++) {
    const input = listInput[i] as HTMLInputElement;
    input.disabled = true;
  }
};

export const delayNavigate = (callback: () => any) => {
  setTimeout(() => {
    callback();
  }, DELAY_TRANSITION);
};

export const concatFullName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

export const formatDate = (date?: string) => {
  return date ? moment(date).format(DATE_FORMAT) : '';
};

export const formatDateTime = (date?: string) => {
  return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const formatDateTimeNow = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);
  if (now.get('day') - inputDate.get('day') === 0) {
    if (i18n.language === 'vi') inputDate.locale('vi');
    else {
      inputDate.locale('en-us');
    }

    return date ? inputDate.fromNow() : '';
  } else return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const isNowDay = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);

  return now.get('day') - inputDate.get('day') === 0;
};

export const convertToHTMLWithSup = (inputString: string) => {
  const outputString = inputString.replace(/m\^(\d)/g, 'm<sup>$1</sup>');

  return outputString;
};

export const getAvatarUrl = () => {
  const avatarUrl =
    Storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO)?.accountDetail?.avatarUrl || '';

  return `${process.env.REACT_APP_IMAGE_URL}?id=${avatarUrl}`;
};

export const getImageURL = (url: string) => {
  return `${process.env.REACT_APP_IMAGE_URL}?id=${url}`;
};

export const fileToBase64 = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      const base64String = String(reader?.result);

      resolve(base64String || '');
    };

    reader.readAsDataURL(file);
  });
};

export const getMimeTypeFromBase64 = (base64: string) => {
  // RegExp to extract the MIME type part of the Base64 string
  const result = base64.match(/^data:(.*?);base64,/);

  if (result && result.length > 1) {
    return result[1];
  } else {
    return null; // or assume a default type, or throw an error, etc.
  }
};

export const getVersion = () => Storage.getStorageItem(STORAGE_KEY.APP_VERSION);

export const isPWA = () =>
  process.env.REACT_APP_DEV_MODE === 'true'
    ? true
    : window.matchMedia('(display-mode: standalone)').matches;

export const generateUUID = () => {
  return uuidv4();
};

export const initPWAConfig = (appConfig: AppConfigType) => {
  const myDynamicManifest = {
    name: appConfig?.appName,
    short_name: appConfig?.appName,
    description: appConfig?.appName,
    start_url: window.location.origin + '/home',
    scope: window.location.origin + '/',
    display: 'standalone',
    theme_color: '#2196F3',
    background_color: '#2196F3',
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const manifest = document.querySelector('#manifest-placeholder');
  const iconLink = document.getElementById('link-icon');
  const iconAppleLink = document.getElementById('link-apple-icon');
  const appName = document.getElementById('app-name');
  if (manifest) {
    manifest.setAttribute('href', manifestURL);
  }
  iconLink?.setAttribute('href', String(appConfig.appLogo));
  iconAppleLink?.setAttribute('href', String(appConfig.appLogo));
  if (appName) {
    appName.innerText = String(appConfig?.appName);
  }
};

export const initConfigApp = async () => {
  const domain = window.location.hostname;
  const response = await getBranchConfig(domain);
  const { data } = response.data;
  let configApp: AppConfigType = data;
  const existedConfig = Storage.getStorageItem(STORAGE_KEY.APP_CONFIG);
  if (_.isEmpty(existedConfig)) {
    configApp = {
      zaloId: '',
      appDomain: 'localhost',
      appName: 'MORA',
      appLogo: 'https://raw.githubusercontent.com/The-New-Tech/apartech-document/staging/logo.svg',
    };
    window.location.reload();
  }
  Storage.setStorageItem(STORAGE_KEY.APP_CONFIG, configApp);

  initPWAConfig(configApp);
};

export const getAppName = () => {
  return !_.isEmpty(AppConfig) ? String(AppConfig?.appName) : 'MORA';
};

export const getAppLogo = () => {
  return !_.isEmpty(AppConfig) ? String(AppConfig?.appLogo) : '/logo.svg';
};
