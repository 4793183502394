/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import Router from './routes/Router';
import ToastOverlay from './components/overlay/ToastOverlay';
import { useDispatch } from './store/Store';
import { commonSelector } from './store/common';
import { getProfile } from './services/auth.service';
import { ROUTES } from './constants/router';
import { getVersion, isSuccessCode } from './utils/common';
import { LocalStorage, STORAGE_KEY } from './utils/localStorage';
import useDirection from './hooks/useDirection';
import { AnimatePresence } from 'framer-motion';
import { getListBuildingAction } from './store/property';
import { getMessagingToken, requestNotifyPermission } from './externals/firebase';
import { registerFcmToken } from './services/notify.service';
import 'moment/locale/en-au';
import 'moment/locale/vi';
import { getAppVersion } from './services/general.service';
import { Dialog } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EXCLUDE_ROUTE = [ROUTES.LOGIN, ROUTES.VERIFY_OTP];
const Storage = new LocalStorage();

function App() {
  const routing = useRoutes(Router);
  const { isShowToast, toastContent } = useSelector(commonSelector);
  const location = useLocation();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initCallDispatch = async () => {
    const version = await getAppVersion();
    const { data } = version.data;

    if (!getVersion()) {
      Storage.setStorageItem(STORAGE_KEY.APP_VERSION, data.settingValue);
    } else if (getVersion() != data.settingValue) {
      Dialog.alert({
        content: `${t('paragraph.new-version')} (${data.settingValue})`,
        confirmText: t('button.reload'),
        onConfirm: () => {
          Storage.setStorageItem(STORAGE_KEY.APP_VERSION, data.settingValue);
          window.location.reload();
        },
        maskStyle: {
          background: 'transparent',
        },
        bodyStyle: {
          boxShadow: 'var(--shadow-01)',
        },
      });
    }

    //NOTE: Get list building
    await dispatch(getListBuildingAction());

    const permission = await requestNotifyPermission();

    if (permission === 'granted') {
      const fcmToken = await getMessagingToken();
      if (fcmToken) {
        Storage.setStorageItem(STORAGE_KEY.NOTIFY_TOKEN, fcmToken);
        registerFcmToken({
          fcmToken,
        });
      }
    }
  };

  const handleTriggerZalo = () => {
    const zaloWidget = document.getElementById('zalo-chat-widget');
    if (zaloWidget) {
      if (location.pathname === ROUTES.HOME) {
        zaloWidget.style.display = 'block';
      } else {
        zaloWidget.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getProfile();
      const { code, data } = response.data;
      if (isSuccessCode(code)) {
        Storage.setStorageItem(STORAGE_KEY.ACCOUNT_INFO, data);
      }
      await initCallDispatch();
    };
    if (!EXCLUDE_ROUTE.includes(location.pathname)) {
      const accessToken = Storage.getStorageItem(STORAGE_KEY.ACCESS_TOKEN);
      if (!accessToken) {
        goTo(ROUTES.LOGIN, {
          replace: true,
        })();
      } else {
        getData();
      }
    }
    handleTriggerZalo();
  }, [location]);

  return (
    <>
      <ToastOverlay isOpen={isShowToast} isError={toastContent.isError} code={toastContent.code} />
      <AnimatePresence exitBeforeEnter>{routing}</AnimatePresence>
    </>
  );
}

export default App;
